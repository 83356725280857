<!--  -->
<template>
  <div>
    <ProcessScheme ref="ProcessScheme"></ProcessScheme>
  </div>
</template>

<script>
import ProcessScheme from '../Main/Product/ProcessScheme'
export default {
  components: { ProcessScheme },
  data() {
    return {}
  },
  mounted() {
    this.$refs.ProcessScheme.openForm('1314092787376852992')
  },
}
</script>
